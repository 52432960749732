import * as React from 'react';
import { CreateConfigStore, createConfigStore } from './createConfigStore';
import type { BrandConfig } from './utils/configMapper';

type StoreProviderProps = {
  children: React.ReactNode;
  initialState: {
    brandConfig?: BrandConfig;
  };
};

export const StoreContext = React.createContext<CreateConfigStore | null>(null);

export function StoreProvider({ children, initialState }: StoreProviderProps) {
  const storeRef = React.useRef<CreateConfigStore>();

  if (!storeRef.current) {
    storeRef.current = createConfigStore(initialState);
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
}
