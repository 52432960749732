import { DatePickerProps } from './DatePicker.types.ts';
import * as Styled from './DatePicker.styled.ts';
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useIsMobile } from '@/hooks/useIsMobile.ts';

export function DatePicker({
  isOneWayTrip,
  dateRange,
  primaryColor,
  primaryColorBg,
  onSelect,
  locale,
}: DatePickerProps) {
  const [preselectedDays, setPreselectedDays] = React.useState<Date[]>([]);

  const { isMobile } = useIsMobile();

  React.useEffect(() => {
    updatePreselectedDays(dateRange.from, dateRange.to);
  }, [dateRange]);

  const updatePreselectedDays = (startDate: Dayjs, endDate: Dayjs) => {
    const generateDateRange = (startDate: Dayjs, endDate: Dayjs) => {
      if (startDate.isAfter(endDate, 'day')) {
        return [];
      }

      return [startDate.toDate()].concat(
        generateDateRange(startDate.add(1, 'day'), endDate)
      );
    };

    const range = generateDateRange(startDate, endDate);

    setPreselectedDays(range);
  };

  const onHover = (date: Date) => {
    const startDate = dayjs(date).isAfter(dayjs(dateRange.from))
      ? dayjs(dateRange.from)
      : dayjs(date);
    const endDate = dayjs(date).isAfter(dayjs(dateRange.to))
      ? dayjs(date)
      : dayjs(dateRange.to);

    updatePreselectedDays(startDate, endDate);
  };

  if (isOneWayTrip) {
    return (
      <Styled.DatePicker
        mode="single"
        numberOfMonths={isMobile ? 12 : 2}
        hideNavigation={isMobile}
        onSelect={onSelect}
        $isOnlyDateRangeFrom
        selected={dateRange.from.toDate()}
        disabled={{ before: new Date() }}
        $color={primaryColor}
        $bgColor={primaryColorBg}
        defaultMonth={dateRange.from.toDate()}
        locale={locale}
      />
    );
  }

  return (
    <Styled.DatePicker
      mode="range"
      numberOfMonths={isMobile ? 12 : 2}
      hideNavigation={isMobile}
      weekStartsOn={1}
      $color={primaryColor}
      $bgColor={primaryColorBg}
      selected={{
        from: dateRange?.from?.toDate(),
        to: dateRange?.to?.toDate(),
      }}
      onSelect={onSelect}
      disabled={{ before: new Date() }}
      defaultMonth={dateRange.from.toDate()}
      locale={locale}
      onDayMouseEnter={onHover}
      modifiers={{
        firstPreselected: preselectedDays[0],
        preselected: preselectedDays,
        lastPreselected: preselectedDays[preselectedDays.length - 1],
      }}
      modifiersClassNames={{
        preselected: 'preselected',
        firstPreselected: 'firstPreselected',
        lastPreselected: 'lastPreselected',
      }}
    />
  );
}
