/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticatedDto } from '../models/AuthenticatedDto';
import type { AuthenticateModel } from '../models/AuthenticateModel';
import type { AuthenticateResultModel } from '../models/AuthenticateResultModel';
import type { AuthenticatorInputDto } from '../models/AuthenticatorInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokenAuthService {

    /**
     * @returns AuthenticateResultModel Success
     * @throws ApiError
     */
    public static postApiTokenAuthAuthenticate({
        requestBody,
    }: {
        requestBody?: AuthenticateModel,
    }): CancelablePromise<AuthenticateResultModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/TokenAuth/Authenticate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns AuthenticatedDto Success
     * @throws ApiError
     */
    public static postApiTokenAuthAuthenticateExternal({
        requestBody,
    }: {
        requestBody?: AuthenticatorInputDto,
    }): CancelablePromise<AuthenticatedDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/TokenAuth/AuthenticateExternal',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
