import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const CallButton = styled(Button)<{
  bgcolor?: string;
}>`
  background: ${(props) => props.bgcolor} !important;
  border-radius: 20px;
  padding: 6px 16px;
  @media (max-width: 1024px) {
    & span:nth-of-type(2) {
      display: none;
    }
    min-width: 40px;
    padding: 0px;
  }
`;

export const Link = styled.a`
  color: #5f5f5f;
  text-decoration: underline;
  &:hover {
    color: #3c3c3c;
    text-decoration: underline;
  }
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
  text-align: left;
`;
