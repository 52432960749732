import { SearchItem } from '@/services/codegen-romulo';
import { fromSearchItemToResultElement } from './fromSearchItemToResultElement';

export const formatAirport = (searchItem: SearchItem) => {
  if (searchItem.subItems.length > 0) {
    const subitemsFormated = searchItem.subItems.map((subItems) =>
      fromSearchItemToResultElement({
        item: subItems.item,
        isSubItem: true,
        disabled: subItems.item.type === 'City' ? true : false,
      })
    );
    return [
      fromSearchItemToResultElement({
        item: searchItem,
        disabled: searchItem.type === 'City' ? true : false,
      }),
      ...subitemsFormated,
    ];
  } else {
    return [
      fromSearchItemToResultElement({
        item: searchItem,
        disabled: searchItem.type === 'City' ? true : false,
      }),
    ];
  }
};
