import type { IConfig } from '@unleash/proxy-client-react';
import { FlagProvider as FlagProviderUnleash } from '@unleash/proxy-client-react';
import * as React from 'react';
import type { FlagProviderProps } from './FlagProvider.types';

const unleashConfig: IConfig = {
  url: window._env_.VITE_UNLEASH_API_URL,
  clientKey: window._env_.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 60,
  appName: 'default',
  environment: window._env_.VITE_UNLEASH_ENV,
};

export function FlagProvider({ children }: FlagProviderProps) {
  const unleashConfigMemo = React.useMemo(() => unleashConfig, []);
  return (
    <FlagProviderUnleash config={unleashConfigMemo}>
      {children}
    </FlagProviderUnleash>
  );
}
