import * as React from 'react';
import { useStore } from '@/store/useStore';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { useTranslate } from '@tolgee/react';
import * as Styled from './FlightTypeSelector.styled';
import { FlightTypeSelectorProps } from './FlightTypeSelector.types.ts';

export function FlightTypeSelector({
  flightType,
  onFlightSelector,
}: FlightTypeSelectorProps) {
  const brandConfig = useStore((s) => s.brandConfig);

  const { primaryColor } = brandConfig.theme;

  const { t } = useTranslate();

  const products = [
    { label: t('search.return'), value: FlightTypes.R },
    { label: t('search.one_way'), value: FlightTypes.OW, selected: true },
  ];

  return (
    <Styled.Container>
      {products.map((el) => (
        <Styled.Item
          key={el.value}
          onClick={() => onFlightSelector(el.value)}
          $selected={flightType === el.value}
          $color={primaryColor}
        >
          {el.label}
        </Styled.Item>
      ))}
    </Styled.Container>
  );
}
