export const europeanCountries = [
  { ES: 'Albania', IT: 'Albania', EN: 'Albania' },
  { ES: 'Alemania', IT: 'Germania', EN: 'Germany' },
  { ES: 'Andorra', IT: 'Andorra', EN: 'Andorra' },
  { ES: 'Armenia', IT: 'Armenia', EN: 'Armenia' },
  { ES: 'Austria', IT: 'Austria', EN: 'Austria' },
  { ES: 'Azerbaiyán', IT: 'Azerbaigian', EN: 'Azerbaijan' },
  { ES: 'Bélgica', IT: 'Belgio', EN: 'Belgium' },
  { ES: 'Bielorrusia', IT: 'Bielorussia', EN: 'Belarus' },
  {
    ES: 'Bosnia y Herzegovina',
    IT: 'Bosnia ed Erzegovina',
    EN: 'Bosnia and Herzegovina',
  },
  { ES: 'Bulgaria', IT: 'Bulgaria', EN: 'Bulgaria' },
  { ES: 'Chipre', IT: 'Cipro', EN: 'Cyprus' },
  { ES: 'Ciudad del Vaticano', IT: 'Città del Vaticano', EN: 'Vatican City' },
  { ES: 'Croacia', IT: 'Croazia', EN: 'Croatia' },
  { ES: 'Dinamarca', IT: 'Danimarca', EN: 'Denmark' },
  { ES: 'Eslovaquia', IT: 'Slovacchia', EN: 'Slovakia' },
  { ES: 'Eslovenia', IT: 'Slovenia', EN: 'Slovenia' },
  { ES: 'España', IT: 'Spagna', EN: 'Spain' },
  { ES: 'Estonia', IT: 'Estonia', EN: 'Estonia' },
  { ES: 'Finlandia', IT: 'Finlandia', EN: 'Finland' },
  { ES: 'Francia', IT: 'Francia', EN: 'France' },
  { ES: 'Georgia', IT: 'Georgia', EN: 'Georgia' },
  { ES: 'Grecia', IT: 'Grecia', EN: 'Greece' },
  { ES: 'Hungría', IT: 'Ungheria', EN: 'Hungary' },
  { ES: 'Islandia', IT: 'Islanda', EN: 'Iceland' },
  { ES: 'Irlanda', IT: 'Irlanda', EN: 'Ireland' },
  { ES: 'Italia', IT: 'Italia', EN: 'Italy' },
  { ES: 'Kazajistán', IT: 'Kazakistan', EN: 'Kazakhstan' },
  { ES: 'Kosovo', IT: 'Kosovo', EN: 'Kosovo' },
  { ES: 'Letonia', IT: 'Lettonia', EN: 'Latvia' },
  { ES: 'Liechtenstein', IT: 'Liechtenstein', EN: 'Liechtenstein' },
  { ES: 'Lituania', IT: 'Lituania', EN: 'Lithuania' },
  { ES: 'Luxemburgo', IT: 'Lussemburgo', EN: 'Luxembourg' },
  {
    ES: 'Macedonia del Norte',
    IT: 'Macedonia del Nord',
    EN: 'North Macedonia',
  },
  { ES: 'Malta', IT: 'Malta', EN: 'Malta' },
  { ES: 'Moldavia', IT: 'Moldavia', EN: 'Moldova' },
  { ES: 'Mónaco', IT: 'Monaco', EN: 'Monaco' },
  { ES: 'Montenegro', IT: 'Montenegro', EN: 'Montenegro' },
  { ES: 'Noruega', IT: 'Norvegia', EN: 'Norway' },
  { ES: 'Países Bajos', IT: 'Paesi Bassi', EN: 'Netherlands' },
  { ES: 'Polonia', IT: 'Polonia', EN: 'Poland' },
  { ES: 'Portugal', IT: 'Portogallo', EN: 'Portugal' },
  { ES: 'Reino Unido', IT: 'Gran Bretagna', EN: 'United Kingdom' },
  { ES: 'República Checa', IT: 'Repubblica Ceca', EN: 'Czech Republic' },
  { ES: 'Rumanía', IT: 'Romania', EN: 'Romania' },
  { ES: 'Rusia', IT: 'Russia', EN: 'Russia' },
  { ES: 'San Marino', IT: 'San Marino', EN: 'San Marino' },
  { ES: 'Serbia', IT: 'Serbia', EN: 'Serbia' },
  { ES: 'Suecia', IT: 'Svezia', EN: 'Sweden' },
  { ES: 'Suiza', IT: 'Svizzera', EN: 'Switzerland' },
  { ES: 'Turquía', IT: 'Turchia', EN: 'Turkey' },
  { ES: 'Ucrania', IT: 'Ucraina', EN: 'Ukraine' },
  { ES: 'Inglaterra', IT: 'Inghilterra', EN: 'England' },
  { ES: 'Escocia', IT: 'Scozia', EN: 'Scotland' },
  { ES: 'Gales', IT: 'Galles', EN: 'Wales' },
  { ES: 'Irlanda del Norte', IT: 'Irlanda del Nord', EN: 'Northern Ireland' },
];
