import { getCookieValue } from '@/store/utils/authenticateExternal/getCookieValue';
import { setCookieValue } from '@/store/utils/authenticateExternal/setCookieValue';
import FingerprintJS2 from 'fingerprintjs2';
import { useEffect, useState } from 'react';

const useFingerprint = () => {
  const currentFingerPrint = getCookieValue('user-fingerprint');
  const currentSessionFingerPrint = getCookieValue('session-user-fingerprint');
  const [fingerprint, setFingerprint] = useState(currentFingerPrint || '');
  const [sessionFingerprint, setSessionFingerprint] = useState(
    currentSessionFingerPrint || ''
  );

  useEffect(() => {
    const generateFingerprint = () => {
      var tokenExpireDate = new Date(Date.now() + 24 * 60 * 60 * 1000);
      if (fingerprint === '') {
        setTimeout(() => {
          FingerprintJS2.get((components) => {
            const values = components
              .map((component) => component.value)
              .join('');
            const hash = FingerprintJS2.x64hash128(values, 31);
            setFingerprint(hash);

            setCookieValue('user-fingerprint', hash, tokenExpireDate);
          });
        }, 500);
      } else if (currentFingerPrint === '') {
        setCookieValue('user-fingerprint', fingerprint, tokenExpireDate);
      }
    };

    const generateSessionFingerprint = () => {
      if (sessionFingerprint === '') {
        setTimeout(() => {
          const generateSessionId = () =>
            'session-' + Math.random().toString(36).substr(2, 9);
          const sessionId = generateSessionId();
          setSessionFingerprint(sessionId);
          setCookieValue('session-user-fingerprint', sessionId);
        }, 500);
      } else if (currentSessionFingerPrint === '') {
        setCookieValue('session-user-fingerprint', sessionFingerprint);
      }
    };

    generateFingerprint();
    generateSessionFingerprint();
  }, []);

  return { fingerprint, sessionFingerprint };
};

export default useFingerprint;
