import { useStore } from '@/store/useStore';

interface useFreeFlightCampaignProps {
  isFreeFlight: boolean;
  departureCountry?: string;
  adults?: number;
  voucherAmount?: number;
}

export function useFreeFlightCampaign(): useFreeFlightCampaignProps {
  const brandConfig = useStore((state) => state.brandConfig);
  const freeFlightConditions = brandConfig?.brand?.freeFlightConditions;

  if (freeFlightConditions) {
    return {
      isFreeFlight: true,
      ...freeFlightConditions,
    };
  }

  return {
    isFreeFlight: false,
  };
}
