export function isEmpty(object: unknown): boolean {
  const safeObject = object || {};

  // prettier-ignore
  const isCountable =
      Object === safeObject.constructor
      || Array === safeObject.constructor

  if (!isCountable) {
    return true;
  }

  return !Object.entries(safeObject).length;
}
