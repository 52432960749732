import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #000000cc;
  border-radius: 6px;
  height: 34px;
  min-width: unset;
  color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  line-height: 14px;
  padding: 0px 8px;
  padding: 5px 8px 5px 46px;
  position: relative;
  overflow: hidden;

  & span {
    z-index: 1;
  }
`;

export const ContainerWithoutIcon = styled(Container)`
  padding: 5px 8px;
`;

export const BadgeBackground = styled.div`
  position: absolute;
  left: -69px;
  height: 200px;
  width: 100px;
  z-index: 0;

  transform: rotate(25deg);
`;

export const LastMinuteLogo = styled.div`
  height: 22px;
  width: 22px;
  position: absolute;
  left: 7px;
  z-index: 1;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/last_minute_deals.svg);
  background-size: contain;
`;

export const FlashSalesLogo = styled.div`
  height: 26px;
  width: 26px;
  position: absolute;
  left: 6px;
  z-index: 1;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://www.volagratis.com/s/hdp/search/images/icons/flash_sales.svg);
  background-size: contain;
`;
