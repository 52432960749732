import * as Styled from './ImagesCarousel.styled';
import { carouselStylesProps } from './utils';

type AnimationProp = 'fade' | 'slide';

export function ImagesCarousel({
  images,
  className,
  url,
  animation = 'slide',
  mapVersion = false,
  hideNavigation,
}: {
  images: string[];
  url?: string;
  className?: any;
  animation?: AnimationProp;
  mapVersion?: boolean;
  hideNavigation: boolean;
}) {
  return (
    <Styled.Carousel
      className={className}
      animation={animation}
      mapversion={mapVersion}
      navButtonsWrapperProps={carouselStylesProps.navButtonsWrapperProps}
      indicatorContainerProps={carouselStylesProps.indicatorContainerProps}
      indicatorIconButtonProps={carouselStylesProps.indicatorIconButtonProps}
      activeIndicatorIconButtonProps={
        carouselStylesProps.activeIndicatorIconButtonProps
      }
      navButtonsAlwaysInvisible={hideNavigation}
      indicators={!hideNavigation}
      NavButton={({ onClick, className, style, next, prev }) => {
        return (
          <Styled.IconContainer
            onClick={(e) => onClick(e)}
            className={className}
            style={style}
          >
            {next && <Styled.ChevronRightIcon />}
            {prev && <Styled.ChevronLeftIcon />}
          </Styled.IconContainer>
        );
      }}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
    >
      {images?.slice(0, 5).map((image, key) => (
        <a href={url} target="_blank">
          <Styled.Image src={image} loading="lazy" key={key} />
        </a>
      ))}
    </Styled.Carousel>
  );
}
