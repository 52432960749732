import { StrapiService } from '@/services/codegen-welfare';
import { brandConfigurationPath } from '@/store/utils/getInitialState';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

export const InvalidateStrapiCache = () => {
  const [loading, setLoading] = useState(false);

  const handleInvalidateStrapiCache = async () => {
    setLoading(true);
    await StrapiService.deleteApiServicesAppStrapiRemoveConfig({
      path: brandConfigurationPath,
    })
      .then(() => {
        console.warn('Strapi Cache Invalidated');
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      onClick={() => handleInvalidateStrapiCache()}
    >
      Invalidate Strapi Cache
    </LoadingButton>
  );
};
