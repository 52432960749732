import * as React from 'react';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { DateSelectorProps } from './DateSelector.types.ts';

export function useDateSelectorEvents(
  dateRange: DateSelectorProps['dateRange'],
  onSelectedDateRange: DateSelectorProps['onSelectedDateRange'],
  onCloseDatePicker?: () => void
) {
  const dateRef = React.useRef(null);

  const onSelect = (date: DateRange | Date) => {
    if (date instanceof Date) {
      if (dayjs(date).isSame(dateRange.from)) {
        return;
      }

      onSelectedDateRange({
        from: dayjs(date),
        //NOTE: it is necessary to have a data to display in the other date selectors
        to: dayjs(date).add(1, 'd'),
      });

      return;
    }

    const isClickToDateFrom =
      dayjs(date.from).isSame(dateRange.from) &&
      dayjs(date.to).isSame(dateRange.from);

    const isClickToDateTo =
      dayjs(date.to).isSame(dateRange.to) &&
      dayjs(date.from).isSame(dateRange.to);

    if (isClickToDateFrom) {
      dateRef.current = date.from;

      return;
    }

    if (dayjs(date.from).isBefore(dateRange.from)) {
      onSelectedDateRange({
        from: dayjs(date.from),
        to: dayjs(dateRange.to),
      });

      dateRef.current = date.from;
    } else {
      if (dayjs(date.to).isBefore(dateRange.to)) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(dateRange.to),
          });
        }

        return;
      }

      if (dayjs(date.to).isAfter(dateRange.to) || isClickToDateTo) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(date.to).add(1, 'd'),
          });

          dateRef.current = date.to;
        }
        return;
      }
    }
  };

  const onRemoveDateRefCurrent = () => (dateRef.current = null);
  return {
    onSelect,
    onRemoveDateRefCurrent,
  };
}
