import dayjs from 'dayjs';
import { useGetParam } from './useGetParam';

export function useGetDefaultDates() {
  const getParam = useGetParam();

  const outboundDate = getParam('outboundDate');

  const returnDate = getParam('returnDate');

  const dateFrom = dayjs().add(3, 'd');

  const dateTo = dayjs(dateFrom).add(3, 'd');

  return {
    from: outboundDate ? dayjs(outboundDate) : dateFrom,
    to: returnDate ? dayjs(returnDate) : dateTo,
  };
}
