import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ChevronLeftIconMui from '@mui/icons-material/ChevronLeft';
import ChevronRightIconMui from '@mui/icons-material/ChevronRight';
import CarouselMui from 'react-material-ui-carousel';

export const Carousel = styled(CarouselMui)<{ mapversion: boolean }>`
  height: 100%;

  ${({ mapversion }) =>
    mapversion
      ? css`
          width: 100%;
        `
      : css`
          @media (min-width: 768px) {
            width: 220px;
          }

          @media (min-width: 1440px) {
            width: 250px;
          }
        `}
`;

export const Image = styled.img`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
`;

export const IconContainer = styled.div`
  outline: none;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
  background-color: #ffff;
  &:hover {
    opacity: 1;
  }
`;

export const ChevronRightIcon = styled(ChevronRightIconMui)`
  color: #74747c;
`;

export const ChevronLeftIcon = styled(ChevronLeftIconMui)`
  color: #74747c;
`;
