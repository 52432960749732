export const setCookieValue = (
  key: string,
  value?: string,
  expireDate?: Date,
  path?: string,
  domain?: string
) => {
  var cookieValue = encodeURIComponent(key) + '=';

  if (value) {
    cookieValue = cookieValue + encodeURIComponent(value);
  }

  if (expireDate) {
    cookieValue = cookieValue + '; expires=' + expireDate.toUTCString();
  }

  if (path) {
    cookieValue = cookieValue + '; path=' + path;
  }

  if (domain) {
    cookieValue = cookieValue + '; domain=' + domain;
  }

  document.cookie = cookieValue;
};
