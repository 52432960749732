export function getColorValues(color) {
  let red = 0;
  let green = 0;
  let blue = 0;

  if (color.includes('#')) {
    const colorRGB = color.replace(/^#/, '');
    const bigint = parseInt(colorRGB, 16);
    if (colorRGB.length === 6) {
      red = (bigint >> 16) & 255;
      green = (bigint >> 8) & 255;
      blue = bigint & 255;
    } else if (colorRGB.length === 3) {
      red = parseInt(colorRGB[0] + colorRGB[0], 16);
      green = parseInt(colorRGB[1] + colorRGB[1], 16);
      blue = parseInt(colorRGB[2] + colorRGB[2], 16);
    } else {
      red = 0;
      green = 0;
      blue = 0;
    }
  } else {
    const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const match = color.match(regex);
    if (!match) {
      red = 0;
      green = 0;
      blue = 0;
    } else {
      const [, r, g, b] = match;
      red = r;
      green = g;
      blue = b;
    }
  }

  let colorValues = {
    red,
    green,
    blue,
  };
  return colorValues;
}
