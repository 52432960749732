import { Points } from '../Points/Points';
import * as Styled from './Rating.styled';
import { RatingProps } from './Rating.types';
import { Star } from './Star';

export function Rating({ stars, opinionsCount, value, language }: RatingProps) {
  return (
    <Styled.Container>
      {stars &&
        Array.from({ length: stars }, (_, i) => i + 1).map((e) => (
          <Star key={`${e}_star`} />
        ))}
      {stars && value && <Styled.Divider />}
      {value && (
        <Points
          number={value}
          opinionsCount={opinionsCount}
          size={12}
          language={language}
        />
      )}
    </Styled.Container>
  );
}
