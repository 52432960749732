import { Favicon } from '@/components/Favicon';
import { AntdProvider } from '@/providers/AntdProvider/AntdProvider';
import { StoreProvider } from '@/store/storeProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Authentication } from './components/Authentication';
import { CookiesConsent } from './components/CookiesConsent/CookiesConsent';
import { DevTools } from './components/DevTools/DevTools';
import { LmnTealium } from './components/LmnTealium/LmnTealium';
import { Router } from './components/Router/Router';
import { useGetInitialConfig } from './hooks/useGetInitialConfig';
import { FlagProvider } from './providers/FlagProvider';
import { MuiProvider } from './providers/MuiProvider';
import { TolgeeProvider } from './providers/TolgeeProvider';
import { localeObject } from './utils/localeObjects';

dayjs.extend(utc);
dayjs.extend(timezone);

export function App() {
  const initialConfig = useGetInitialConfig();

  React.useEffect(() => {
    if (!!initialConfig) {
      dayjs.locale(
        initialConfig?.language,
        localeObject[initialConfig?.language]
      );
    }
  }, [initialConfig]);

  if (!initialConfig) {
    return null;
  }

  return (
    <BrowserRouter>
      <StoreProvider initialState={initialConfig}>
        <FlagProvider>
          <TolgeeProvider>
            <AntdProvider>
              <MuiProvider>
                <Router />
                <Favicon />
                <Authentication />
                <CookiesConsent />
                <LmnTealium />
                <DevTools />
              </MuiProvider>
            </AntdProvider>
          </TolgeeProvider>
        </FlagProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}
