/* eslint-disable no-param-reassign */
import { MutableRefObject } from 'react';

export type CommonElementInjector = {
  inject: () => Promise<void>;
};

const reinjectScriptTags = (injectedElement: Element): void => {
  Array.from(injectedElement.querySelectorAll('script')).forEach((oldScript) => {
    const newScript = document.createElement('script');
    Array.from(oldScript.attributes).forEach((attr) => newScript.setAttribute(attr.name, attr.value));
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript.parentNode?.replaceChild(newScript, oldScript);
  });
};

const fetchHtml = async (url: string): Promise<string> => (await fetch(url)).text();

const createCommonStaticElementInjector = (element: HTMLElement | null, url: string): CommonElementInjector => ({
  inject: async (): Promise<void> => {
    if (element) {
      const e = await fetchHtml(url);
      element.innerHTML = e
      
      reinjectScriptTags(element);
    }
  },
});

export const createCommonElementInjector = (
  ref: MutableRefObject<HTMLDivElement | null>,
  elementResource: string
): CommonElementInjector => createCommonStaticElementInjector(ref.current, elementResource);
