import { useCountDown } from '@/hooks/useCountDown';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './Counter.styled';

export function Counter({
  date,
  disabledIcon,
}: {
  date: string;
  disabledIcon?: boolean;
}) {
  const countdown = useCountDown(date);

  const brandConfig = useStore((state) => state.brandConfig);

  const { brandBase, brandOrigin } = brandConfig.brand;

  const isVolagratis = brandBase === 'Volagratis';

  const isFromLM = brandOrigin === 'Lastminute';

  const { t } = useTranslate();

  const Text = () => {
    return (
      <>
        <span>{t('general.date.end.in')}</span>
        {countdown[0] === 0 ? (
          <span>{`${countdown[1]}${t('general.format.h')}:${countdown[2]}${t(
            'general.format.m'
          )}:${countdown[3]}${t('general.format.s')}`}</span>
        ) : (
          <span>{`${countdown[0]}${t('general.format.d')}:${countdown[1]}h:${
            countdown[2]
          }${t('general.format.m')}`}</span>
        )}
      </>
    );
  };

  if (countdown[2] < 0) {
    return null;
  }

  if (disabledIcon) {
    return (
      <Styled.ContainerWithoutIcon>
        <Text />
      </Styled.ContainerWithoutIcon>
    );
  }

  return (
    <Styled.Container>
      {isVolagratis ? <Styled.FlashSalesLogo /> : <Styled.LastMinuteLogo />}

      <Styled.BadgeBackground
        style={{
          backgroundColor: isFromLM
            ? isVolagratis
              ? '#ffb511'
              : '#f2007d'
            : brandConfig.theme.primaryColor,
        }}
      />
      <Text />
    </Styled.Container>
  );
}
