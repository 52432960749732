import * as React from 'react';
import { useStore } from '@/store/useStore';

export function Favicon() {
  const brandConfig = useStore((state) => state.brandConfig);

  const theme = brandConfig.theme;

  React.useEffect(() => {
    const faviconElement = document.getElementById('favicon');

    if (faviconElement) {
      faviconElement.setAttribute('href', theme.favicon);
    }
  }, []);

  return null;
}
