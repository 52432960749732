import { LocationServiceService } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { formatAirport } from '../utils/formatAirport';

const useGetAirports = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { language, country } = brandConfig;

  const getAirports = async (phrase: string) => {
    const response =
      await LocationServiceService.postApiServicesAppLocationServiceGetAirports(
        {
          requestBody: {
            language: `${language}-${country}`,
            phrase,
            courseType: 'Departure',
            purpose: 'Package',
          },
        }
      );

    return response.items.flatMap((e) => formatAirport(e));
  };

  return getAirports;
};

export { useGetAirports };
