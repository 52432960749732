import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  background: rgb(245, 245, 245);
  border-radius: 12px;
  position: relative;
  padding: 4px 4px 6px 4px;
`;

export const Item = styled.div<{ $selected?: boolean; $color: string }>`
  height: 27px;
  cursor: pointer;
  margin: auto 0px;
  padding: 4px 16px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
  border: 0px solid rgb(186, 186, 192);
  box-shadow: unset;
  color: rgb(60, 60, 60);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  ${({ $selected, $color }) =>
    $selected &&
    css`
      background-color: rgb(255, 255, 255);
      border-radius: 8px;
      border: 0px solid rgb(186, 186, 192);
      box-shadow:
        rgba(0, 0, 0, 0.04) 0px 4px 8px,
        rgba(0, 0, 0, 0.06) 0px 0px 2px,
        rgba(0, 0, 0, 0.04) 0px 0px 1px;
      color: ${$color};
      font-size: 14px;
      font-weight: 700;
    `}
`;
