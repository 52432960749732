import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 10px 20px,
    rgba(0, 0, 0, 0.04) 0px 2px 10px,
    rgba(0, 0, 0, 0.02) 0px 0px 4px;
`;

export const SearchContainer = styled.div<{ $isFreeFlight: boolean }>`
  display: grid;
  gap: 8px;
  grid-template: 'autocompleters calendar participants button' / 3fr 1.75fr 1.27fr 56px;
  ${({ $isFreeFlight }) =>
    $isFreeFlight
      ? " grid-template: 'autocompleters calendar button' / 3fr 1.75fr 56px;"
      : " grid-template: 'autocompleters calendar participants button' / 3fr 1.75fr 1.27fr 56px;"}
  padding: 16px;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    grid-template: unset;
    padding-bottom: 16px;
  }
`;

export const SearchText = styled.span`
  margin-left: 4px;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Button = styled.button<{ $color: string }>`
  align-items: center;
  background: ${({ $color }) => $color};
  border-radius: 8px;
  border: none;
  box-shadow: ${({ $color }) => $color} 0px 0px 0px 0px inset;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 100%;
  min-height: 56px;
  line-height: 56px;
  justify-content: center;
  outline: none;
  padding: 0px;
  transition: all 0.2s ease 0s;
  width: 100%;
`;

export const Switch = styled.span`
  @media (max-width: 992px) {
    display: none;
  }
`;
export const SwitchMobile = styled.div`
  display: none;
  @media (max-width: 992px) {
    text-align: right;
    width: 100%;
    display: block;
    padding: 0px 8px 8px 8px;
    margin-top: -8px;
  }
`;

export const FlightOptions = styled.div`
  padding: 16px 16px 0px 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 54px;
`;
