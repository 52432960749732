import { HotelBedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useStore } from '@/store/useStore';
import { Box } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Styled from './ProductSelector.styled';
import { ProductType } from '../../Search.types';

export function ProductSelector({
  productType,
  onSelectorTab,
}: {
  productType?: ProductType;
  onSelectorTab?: (product: string) => void;
}) {
  const { t } = useTranslate();

  const brandConfig = useStore((s) => s.brandConfig);

  const { primaryColor } = brandConfig.theme;

  const availableProducts = [ProductType.F, ProductType.DP, ProductType.H];

  const products = [
    {
      label: t('general.product.dp'),
      icon: (
        <span>
          <PlaneNEIcon style={{ marginRight: '4px' }} />
          <HotelBedIcon />
        </span>
      ),
      id: ProductType.DP,
    },
    {
      label: t('general.product.hotel'),
      icon: <HotelBedIcon />,
      id: ProductType.H,
    },
    {
      label: t('general.product.flight'),
      icon: <PlaneNEIcon />,
      id: ProductType.F,
    },
  ];

  return (
    <Box sx={{ borderBottom: 1, borderColor: '#eaeaeb', paddingTop: 1 }}>
      <Styled.Container>
        {products
          .filter((e) => availableProducts.includes(e.id))
          .map((el) => (
            <Styled.Item
              key={el.id}
              onClick={() => onSelectorTab(el.id)}
              $selected={productType === el.id}
              $color={primaryColor}
            >
              {el.icon}
              {el.label}
            </Styled.Item>
          ))}
      </Styled.Container>
    </Box>
  );
}
