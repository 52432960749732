import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
`;

export const Item = styled.div<{ $selected?: boolean; $color: string }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition:
    color 0.2s ease 0s,
    border-bottom 0.2s ease 0s;

  & span {
    font-size: 24px;
    margin-right: 8px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    & span {
      margin-right: 0px;
    }
  }

  ${({ $selected, $color }) =>
    $selected &&
    css`
      border-bottom: 2px solid ${$color};
      color: ${$color};
      font-weight: 700;
      font-size: 16px;
      & svg {
        fill: ${$color};
      }
    `}
`;
