import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  border-radius: 8px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 8px;
    background-color: unset;
  }
`;
export const Divider = styled.div`
  background-color: rgb(186, 186, 192);
  height: 32px;
  min-width: 1px;

  @media (max-width: 992px) {
    display: none;
  }
`;
