type StylesProps = React.CSSProperties;

type CarouselStylesProps = Record<string, { style: StylesProps }>;

export const carouselStylesProps: CarouselStylesProps = {
  navButtonsWrapperProps: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 16px',
    },
  },
  indicatorContainerProps: {
    style: {
      bottom: '16px',
      position: 'absolute',
      zIndex: 2,
      marginRight: '4px',
    },
  },
  indicatorIconButtonProps: {
    style: {
      color: 'rgba(255, 255, 255, 0.5)',
      height: '6px',
      width: '6px',
      marginRight: '4px',
    },
  },
  activeIndicatorIconButtonProps: {
    style: {
      backgroundColor: '#fff',
      height: '6.5px',
      width: '6.5px',
    },
  },
};
