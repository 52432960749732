import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

const eudaimon =
  'AyP%2F5am6Pek4AWiVCP1G2Z6OqjAJhfRNAWOFetMSEgO6qrPhU%20EkqvF4IHd%2FaqFbkrf0AfknNldcFglzUf3wmoRY0YOdK085f5R2pVqLwUNXh2oEAgGz%20PSlvPILwVT2QMG7vY0CcAoI41BDvb1Oracalsxgn9L8h5Fznjd9AWjENDBQ%20FaU2jepTRM5v1%200C0uhcceM58aiCKSDRc7V8e%20T48PFa8bNa9PxNccuEX76%20w8%20ov7jMX%20kW4pjgcBJ70gLyanUzxjG8OXWlU67YPDnAIk%2F5M3slxvaBBbkKy10GfHX85nBLcSaZHoNYWHr';
const willis =
  'smtY6q01Ypi1iIxNUZ3G2k%2FDeZ0n4MDC%2F6Or0tcFasshGTVgTVTQeY02uDgvwkFjwlpKX%2FmnyWEXbpAFqS8l1KMKR0xY3BXHYUdb7O0huXqctVLH2Paj%20ogLOww%20i1SQHed9yf50fBwoLIe%20WXTTHinBOxrsTfj6hBa%2FzQDoVnf4qVpvds4phKRdYbHkf7O%20PORcOVbwRTgk%20rMjle%2FHQ%20UMaOFVAm%2FqO7cM6XUW%20xcS333GThU3gAsqZ7Z6m1Pp0iCzO9bqLOnzxexHyGyA8uUUqfT1P95%20EOQ3WMpA0bR6J4HypL38Lu1SMLKlQj%2Fu9Qph4kSdxaYJ%20CTBeoG%2FBrcmynmtU38GycrfySBgqRzk8kMGPHQBLTw08dgHkxBbOYHfy2aw5jIVYEF%2F%20XB2HCILZK6XZHeWTnYpUKkJB7FYY8VhRIU0uOG1JYY4Luip%2FnZMzRm8AbXS6nu66Gcorjmh1N08xYrClChVFQRONlUuXzC85Eu9WM%2FsgyGWQlo9zWK7MnnD6euQj7PkdQqL5G3yFkcu%2F2q%20SGDeiYP7A3LEzNw1QAA9gwbLJf1r6OaSotJ9%20UGt5mxUee93gq3fmFZVPkVrNBURqxChgMXO7Gs%2F4PqguyepTwYUvVYmWhaYve8KKoIHurjGFirJAeYdA39ylcpLS2g9pL4NIxK6Tx8%3D';

const pellegrini =
  'WYBhbLkdfWd00yMkr04SBFXSBl%20KBK28EER%2014hk9kPJkwZHeS0B6rBZWjzAqBLhYyd207NbX2o1mKbplOSsupobd8bel550QXrctU4gySQaXMEmzWc7QZNvkOlHaKQ0n9gKaBbaDF8kEBle8EJELkWL6ZaEXS%2Fb83h34U6j2M8w7IAUWbo0MhxbDMpBr09Edo41VH%20nhATYuLJdeLpBNAotgXxPSxV1zayXbYyGv8sn0D22alH2ckLBNONBPlzHReV%20Z99T%20ktL3Veq0nXfV3Sk1YCQdNROJDwvWhlwKdrWhLW%2FTNRQZB2Yl6hGxlkxOLvRNJWkPdU9V5Gzz9yXa12yz1R3vZjPkufq9i6NbFQd7E5UAX4OHEmzHwd6OHG9Zo1lgg9Ku5f8AEv83DBOvbq5rq3nXyFyRbHC57nEh8SvdGKEyAmrvzVyVO7%20Bdd5MoT391rwF5h2cA3tMT12mi%20YA4xnFh1QIiyidkkL7nyLCv8vsX9wMV%20qxYasRH1kHZ4nU8yQWsKNufb9xyNpN11dFnbmBhj9toweA4AD6sD0mXvaVIZexJarkvZbfnQPjsOyU4nn1TBoEIykggh5WaBjyNr0yWDg%20xwaaPPwytmubnQEmQfydN%20F0ln0rgsCqU0jCPBgY7P8Nv7o%20gMuOg%3D%3D';

const reward =
  '5NIG7VoDwA322J6rgGot4/x1MkYFsqklSPH6dwCuEYDOLpyLE2pZsgqSe7DqTWGemfS4ESVijHKkwCRV5gmE5wOGuwJAAkgZgTpTFxwOL4cShDczfpnBr5n+fzSkcHDeFOLh410oFIxOEfPvPJFfv1lRd81QLY6bkpTPwggMzOexr9wBXnmUQiA6/AzUowLOxEZOPZlA4rChe1oK8m59vEzkvddix5UKzslXbmNdx9D/RjFnZjtsypmJ+oA25N6r38/LnDW1PnXabM1dxVRBDOyaCbc3fsmfpcHm/PKh+J/48sOVvVY/zYWdO/sVmJTDb2IZVAVgGCbvMtV4PQpxnXXr3iQHDqR+EMXbSCrusfw=';
export const AddSSOToken = () => {
  const handleAddToken = async (token: string) => {
    window.location.replace(`/?a=${token}`);
  };

  return (
    <Stack gap={'8px'}>
      <LoadingButton variant="text" onClick={() => handleAddToken(eudaimon)}>
        Add Eudaimon Token
      </LoadingButton>
      <LoadingButton variant="text" onClick={() => handleAddToken(willis)}>
        Add Willis Token
      </LoadingButton>
      <LoadingButton variant="text" onClick={() => handleAddToken(pellegrini)}>
        Add Pellegrini Token
      </LoadingButton>
      <LoadingButton variant="text" onClick={() => handleAddToken(reward)}>
        Add Reward Token
      </LoadingButton>
    </Stack>
  );
};
