import { useStore } from '@/store/useStore';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { ClearXIcon } from '../../LmnIcons/LmnIcons';
import * as Styled from '../PassengerSelector.styled';
import { RoomProps } from '../PassengerSelector.types';
import { refineAge } from '../utils/refineAge';
import { AddChildSelector } from './AddChildSelector';
import { useBookingType } from '@/hooks/useBookingType';
import { useIsMobile } from '@/hooks/useIsMobile';

export function Room({
  roomNumber,
  adults,
  minors,
  canAddRoom,
  canAddPassengers,
  updateAdults,
  updateChild,
  removeChild,
  addRoom,
  removeRoom,
  roomsAmount,
}: RoomProps) {
  const { t } = useTranslate();
  const bookingType = useBookingType();

  const { isMobile } = useIsMobile();

  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor } = brandConfig.theme;

  const isLast = roomsAmount - 1 === roomNumber;

  const infantAmount = minors.filter((m) => m < 2).length;

  return (
    <Styled.RoomContainer
      $onlyOne={roomsAmount !== 1}
      $isMobile={isMobile}
      data-test="home-search-form-passengers-room"
    >
      {bookingType !== 'Flight' && (
        <>
          <Styled.TitleContainer>
            <Styled.Title>{`${t('search.room')} ${roomNumber + 1}`}</Styled.Title>
            {roomNumber !== 0 && (
              <Styled.Button
                onClick={removeRoom}
                style={{ color: primaryColor }}
              >
                {t('search.remove_room')}
              </Styled.Button>
            )}
          </Styled.TitleContainer>
          {adults < infantAmount && (
            <Styled.TooMuchInfantsDisclaimer>
              {t('search.infants.too_much')}{' '}
              {/* TODO Disable search if adults < infantAmount */}
            </Styled.TooMuchInfantsDisclaimer>
          )}
        </>
      )}
      <Styled.Row>
        <Styled.RowText>
          <span style={{ fontSize: 16 }}>{t('search.adults')}</span>
          <span>+12</span>
        </Styled.RowText>
        <Styled.ButtonContainer>
          <Styled.RoomButton
            $color={primaryColor}
            onClick={() => adults > 0 && updateAdults(adults - 1)}
            disabled={adults <= 1}
          >
            <MinusOutlined size={24} />
          </Styled.RoomButton>
          <Styled.Counter>{adults}</Styled.Counter>
          <Styled.RoomButton
            id="search-form-add-passenger-button"
            $color={primaryColor}
            disabled={!canAddPassengers}
            onClick={() => updateAdults(adults + 1)}
          >
            <PlusOutlined size={20} />
          </Styled.RoomButton>
        </Styled.ButtonContainer>
      </Styled.Row>
      {minors.map((child, minorsIndex) => (
        <Styled.Row>
          <Styled.RowText>
            <span style={{ fontSize: 16 }}>
              {t(child < 2 ? 'search.baby' : 'search.child')}
            </span>
            <span>{refineAge(child, 2, t)}</span>
          </Styled.RowText>
          <ClearXIcon
            onClick={() => removeChild(roomNumber, minorsIndex)}
            size={20}
            style={{ color: 'rgb(186, 186, 192)', marginRight: '2px' }}
          />
        </Styled.Row>
      ))}
      <AddChildSelector
        updateChild={updateChild}
        disabled={!canAddPassengers}
        canAddInfant={adults > infantAmount}
      />
      {!isMobile && canAddRoom && isLast && bookingType !== 'Flight' && (
        <Styled.AddRoom>
          <Styled.Button
            style={{
              color: canAddPassengers ? primaryColor : '#3c3c3c',
            }}
            onClick={addRoom}
            disabled={!canAddPassengers}
          >
            {t('search.add.room')}
          </Styled.Button>
        </Styled.AddRoom>
      )}
    </Styled.RoomContainer>
  );
}
