import { useIsMobile } from '@/hooks/useIsMobile';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { ClickAwayListener } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Locale from 'date-fns/locale';
import dayjs from 'dayjs';
import * as React from 'react';
import 'react-day-picker/style.css';
import * as Styled from './DateSelector.styled.tsx';
import { ProductType } from '../../Search.types.ts';
import { DateSelectorProps } from './DateSelector.types.ts';
import { Modal } from '@/components/Modal';
import { useDateSelectorEvents } from './useDateSelectorEvents.ts';
import { DatePicker } from './DatePicker/index.ts';

export function DateSelector({
  isOneWayTrip,
  dateRange,
  onSelectedDateRange,
  productType,
}: DateSelectorProps) {
  const [openDateSelector, setOpenDateSelector] = React.useState(false);

  const onCloseDatePicker = () => setOpenDateSelector(false);

  const { onSelect, onRemoveDateRefCurrent } = useDateSelectorEvents(
    dateRange,
    onSelectedDateRange,
    onCloseDatePicker
  );

  const { isMobile } = useIsMobile();

  const track = useTrack();

  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, primaryColorBg } = brandConfig.theme;

  const { t } = useTranslate();

  const nightsAmount = dayjs(dateRange.to).diff(dateRange.from, 'days');

  const locale = Locale[brandConfig.language];

  const handleOpenSelector = () => {
    setOpenDateSelector(true);

    track({
      eventName: 'opened.dates-selector',
      logLevel: 'info',
    });
  };

  const handleCloseSelector = () => {
    setOpenDateSelector(false);
    onRemoveDateRefCurrent();
    track({
      eventName: 'selected.dates',
      logLevel: 'info',
      eventProperties: {
        from: dateRange?.from?.format(),
        to: dateRange?.to?.format(),
      },
    });
  };

  const valueTextField = isOneWayTrip
    ? `${dateRange?.from?.format('ddd, DD MMM')}`
    : `${dateRange?.from?.format('ddd, DD MMM')} ${dateRange?.to ? ` - ${dateRange?.to?.format('ddd, DD MMM')}` : ''}`;

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Input
          label={t('search.when')}
          value={valueTextField}
          onClick={() => handleOpenSelector()}
          fullWidth
          autoComplete="off"
          slotProps={{ inputLabel: { shrink: true } }}
        />
      </Styled.InnerContainer>
      {openDateSelector && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.CalendarContainer>
              <DatePicker
                isOneWayTrip={isOneWayTrip}
                onSelect={onSelect}
                dateRange={dateRange}
                primaryColor={primaryColor}
                primaryColorBg={primaryColorBg}
                locale={locale}
              />
              {productType !== ProductType.F && (
                <Styled.NightsCounter>
                  {nightsAmount}{' '}
                  {nightsAmount >= 2 ? t('general.nights') : t('general.night')}
                </Styled.NightsCounter>
              )}
            </Styled.CalendarContainer>
          }
          onClickAway={handleCloseSelector}
        />
      )}
      {isMobile && (
        <Modal
          title={t('search.dates')}
          isModalOpen={openDateSelector}
          fullHeight
          onCloseModal={handleCloseSelector}
        >
          <Styled.MobileCalendarContainer>
            <Styled.MobileDatesContainer>
              {!isOneWayTrip && (
                <Styled.Item $selected={false} $color={primaryColor}>
                  {dateRange?.from?.format('ddd, DD MMM')}
                </Styled.Item>
              )}
              <Styled.DatesDivider>-</Styled.DatesDivider>
              <Styled.Item $selected={false} $color={primaryColor}>
                {dateRange?.to?.format('ddd, DD MMM')}
              </Styled.Item>
            </Styled.MobileDatesContainer>
            <Styled.DateSelectorContainer>
              <DatePicker
                isOneWayTrip={isOneWayTrip}
                onSelect={onSelect}
                dateRange={dateRange}
                primaryColor={primaryColor}
                primaryColorBg={primaryColorBg}
                locale={locale}
              />
            </Styled.DateSelectorContainer>
          </Styled.MobileCalendarContainer>
        </Modal>
      )}
    </Styled.Container>
  );
}
