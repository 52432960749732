export const getHDPFacets = (filters: any) => {
  const priceRange =
    filters.budgetRange?.length > 0
      ? [
          {
            name: 'price-range',
            values: [
              filters.budgetRange[0].toString(),
              filters.budgetRange[1].toString(),
            ],
          },
        ]
      : [];

  const deals = filters.deals
    ? [
        {
          name: 'promotion',
          values: ['FLASH_SALE'],
        },
      ]
    : [];

  const freeCancellation =
    filters?.freeCancellation?.length > 0
      ? [
          {
            name: 'free-cancellation',
            values: ['true'],
          },
        ]
      : [];

  const propertyTypes =
    filters.propertyType && filters.propertyType.length > 0
      ? [
          {
            name: 'property-type',
            values: filters.propertyType,
          },
        ]
      : [];

  const stars =
    filters.stars && filters.stars.length > 0
      ? [
          {
            name: 'stars',
            values: filters.stars,
          },
        ]
      : [];

  const rating =
    filters.rating && filters.rating.length > 0
      ? [
          {
            name: 'opinion-rating',
            values: filters.rating,
          },
        ]
      : [];

  const amenities =
    filters.amenities && filters.amenities.length > 0
      ? [
          {
            name: 'amenities',
            values: filters.amenities,
          },
        ]
      : [];

  const characteristics =
    filters.characteristics && filters.characteristics.length > 0
      ? [
          {
            name: 'characteristics',
            values: filters.characteristics,
          },
        ]
      : [];

  const mealPlans =
    filters.mealPlan && filters.mealPlan.length > 0
      ? [
          {
            name: 'meal-plan',
            values: filters.mealPlan,
          },
        ]
      : [];

  const coordinates = filters.mapBoundingBox
    ? [
        {
          name: 'coordinates',
          boundingBox: filters.mapBoundingBox,
        },
      ]
    : [];

  const facets = [
    ...priceRange,
    ...deals,
    ...freeCancellation,
    ...propertyTypes,
    ...stars,
    ...rating,
    ...amenities,
    ...characteristics,
    ...mealPlans,
    ...coordinates,
  ];

  return facets;
};
