import { SearchItem } from '@/services/codegen-romulo';

export const getTitle = (location: SearchItem) => {
  switch (location.type) {
    case 'City':
      return `${location.name}`;
    case 'Airport':
      return `${location.location} (${location.code})${
        location.name ? ' - ' + location.name : ''
      }`;
    case 'Tag':
      return `${location.name}`;
    case 'Hotel':
      return `${location.name}`;
    default:
      return `${location.location} (${location.code})`;
  }
};
