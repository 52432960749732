import { useStore } from '@/store/useStore';
import { getSearchParam } from '@/utils/getSearchParam';
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

export function Authentication() {
  const [getExternalAuth, brandConfig] = useStore((state) => [
    state.getExternalAuth,
    state.brandConfig,
  ]);

  const navigate = useNavigate();

  const [fetchingExternalAuth, setFetchingExternalAuth] =
    useState<boolean>(false);

  const getExternalAuthentication = () => {
    if (!fetchingExternalAuth) {
      setFetchingExternalAuth(true);
      const aTokenParam = getSearchParam('a');
      const aToken = encodeURIComponent(
        aTokenParam === null ? '' : aTokenParam
      );
      const externalAuthRequest = {
        token: aToken ? decodeURIComponent(aToken.replaceAll('%20', '+')) : '',
        domainName: brandConfig.domain,
      };

      getExternalAuth(externalAuthRequest)
        .then((response) => {
          !response &&
            navigate({
              pathname: `/exception`,
              search: `?${createSearchParams({
                type: '401',
              })}`,
            });
        })
        .finally(() => setFetchingExternalAuth(false));
    }
  };
  useEffect(() => {
    window.location.pathname !== '/exception' && getExternalAuthentication();
  }, []);
  return null;
}
