import { useTranslate } from '@tolgee/react';
import { Col, Row } from 'antd';
import * as Styled from './TravelSearchInfo.styled';
import { TravelSearchInfoProps } from './TravelSearchInfo.types';

export function TravelSearchInfo({
  title,
  subtitle,
  description,
}: TravelSearchInfoProps) {
  const { t } = useTranslate();

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Row justify={'center'}>
          <Col>
            <Styled.Title>{t(title)}</Styled.Title>
            {subtitle && <Styled.Subtitle>{t(subtitle)}</Styled.Subtitle>}
            <Styled.Text dangerouslySetInnerHTML={{ __html: t(description) }} />
          </Col>
        </Row>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
