import { useContext } from 'react';
import { useStore as useStoreZustand } from 'zustand';
import { StoreStateProps } from './createConfigStore';
import { StoreContext } from './storeProvider';

export function useStore<T>(selector: (state: StoreStateProps) => T): T {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing useStore.Provider in the tree');
  return useStoreZustand(store, selector);
}
