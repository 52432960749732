import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.p<{ $primaryColor: string }>`
  color: #999999;
  font-size: 12px;
  width: 100%;
  margin-bottom: 16px;

  padding-left: 16px;
  padding-right: 16px;

  a {
    color: ${({ $primaryColor }) => $primaryColor};
  }

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  font-size: 14px;
  font-weight: 700;
  & a {
    color: #999999;
    text-decoration: none;
  }
`;
