import { useGetParam } from './useGetParam';

export enum BookingType {
  FlightAndHotel = 'FlightAndHotel',
  Hotel = 'Hotel',
  Flight = 'Flight',
}

export function useBookingType(): BookingType {
  const getParam = useGetParam();

  const product = getParam('product');

  switch (product) {
    case 'DP':
      return BookingType.FlightAndHotel;
    case 'H':
      return BookingType.Hotel;
    case 'F':
      return BookingType.Flight;
    default:
      return undefined;
  }
}
