import { Counter } from '@/components/Counter';
import { Rating } from '@/components/HotelCard/Rating';
import { HotelBedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import PlaceIcon from '@mui/icons-material/Place';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Deals.styled';
import { FlashSaleOffersBackground } from './FlashSaleOffersBackground';

export const Offers: FC = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { t } = useTranslate();
  const offers = brandConfig.offers;
  const isFromLM = brandConfig.brand.brandOrigin === 'Lastminute';
  const primaryColor = brandConfig.theme.primaryColor;
  const currencyFormatter = useCurrencyFormatter();

  const dealList = offers.dealList;

  if (isEmpty(dealList)) {
    return null;
  }

  return (
    <Styled.FlashSaleOffersContainer>
      <FlashSaleOffersBackground primaryColor={primaryColor} />
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>{t(offers.dealsTitleKey)}</Styled.SectionTitle>
            <Styled.Subtitle>{t(offers.dealsSubtitleKey)}</Styled.Subtitle>
          </div>
          <Styled.AtolImage />
        </Styled.TitleContainer>
        <Styled.OffersCarousel>
          {dealList.map((e) => (
            <Styled.OfferContainer>
              <Styled.ImagesCarouselContainer>
                <Styled.OfferType>
                  <PlaneNEIcon style={{ marginRight: '2px' }} />
                  <HotelBedIcon style={{ marginRight: '8px' }} />
                  {t(`general.product.${e.offerType.toLowerCase()}`)}
                </Styled.OfferType>
                <Styled.CounterContainer>
                  <Counter date={e.endsIn} />
                </Styled.CounterContainer>

                <Styled.Location>
                  <PlaceIcon sx={{ fontSize: 16, marginRight: '4px' }} />
                  {e.city}
                </Styled.Location>
                <Styled.ImagesCarousel
                  images={e?.images?.data?.map((e) => e.attributes.url)}
                  hideNavigation={false}
                />
              </Styled.ImagesCarouselContainer>
              <Styled.OfferContent href={e.url}>
                <Styled.OfferName>{e.offerName}</Styled.OfferName>
                <Styled.HotelName>
                  {e.hotelName} <Rating stars={e.rating as 1} language={'en'} />
                </Styled.HotelName>
                <Styled.Details>{e.description}</Styled.Details>
                <Styled.PriceContainer
                  $fromLM={isFromLM}
                  $textColor={primaryColor}
                >
                  {t('general.price.from')}{' '}
                  <Styled.Price>
                    {currencyFormatter.formatNumber(e.pricePerPerson)}
                  </Styled.Price>{' '}
                  {t('general.price.pp')}
                </Styled.PriceContainer>
              </Styled.OfferContent>
            </Styled.OfferContainer>
          ))}
        </Styled.OffersCarousel>
      </Styled.InnerContainer>
    </Styled.FlashSaleOffersContainer>
  );
};
