import { UserIcon } from '@/components/LmnIcons/LmnIcons';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';

export const AuthenticationButton = styled(Button)<{
  bgcolor?: string;
}>`
  background: ${(props) => props.bgcolor} !important;
  border-radius: 20px;
  padding: 6px;
  @media (max-width: 1024px) {
    & span:nth-of-type(2) {
      display: none;
    }
    padding: 0px;
  }
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
  text-align: left;
`;

export const User = styled(UserIcon)`
  color: black;
`;

export const UserCircle = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 7px;
  width: 32px;
  height: 32px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

export const ExpandIcon = styled(KeyboardArrowDownIcon)``;

export const ContractIcon = styled(KeyboardArrowDownIcon)`
  rotate: 180deg;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const UserName = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
export const BrandName = styled.span``;
export const UserBudget = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
