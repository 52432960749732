import {
  AdvancedSearchInput,
  CartApiServiceService,
  CartDetailResponseDto,
  ConfigResponseDto,
  CreateCartResponseDto,
  NewCartInputDto,
  SearchInputDto,
  SearchTransportInputDto,
  ShoppingHdpServiceService,
  ShoppingTransportServiceService,
} from '@/services/codegen-romulo';
import {
  AuthenticatedDto,
  AuthenticatorInputDto,
  TokenAuthService,
} from '@/services/codegen-welfare';

import { createStore } from 'zustand';
import { setCookieValue } from './utils/authenticateExternal/setCookieValue';
import {
  encriptedAuthTokenName,
  tokenCookieName,
} from './utils/authenticateExternal/tokenNames';
import type { BrandConfig } from './utils/configMapper';
import { getFlightFacets } from './utils/getFlightFacets';
import { getHDPFacets } from './utils/getHDPFacets';

const SORT_SELECTOR_DEFAULT = 'RECOMMENDED';

const SORT_SELECTOR_FLIGHT_CAMPAING = 'PRICE';

export type CreateConfigStore = ReturnType<typeof createConfigStore>;

type SortSelectorProps = {
  value: string;
  type?: string;
  ascending: boolean;
};

export interface StoreStateProps {
  brandConfig: BrandConfig | null;
  getExternalAuth: (
    request: AuthenticatorInputDto
  ) => Promise<AuthenticatedDto>;
  externalAuth: AuthenticatedDto;
  sortSelector: SortSelectorProps;
  flightFilters: any;
  hdpFilters: any;
  loading: boolean;
  retrieveProporsals: any;
  cartDetail?: CartDetailResponseDto;
  cartConfig?: ConfigResponseDto;
  availableAncilliaries?: Record<string, any>;
  order?: any;
  currentSearchId: string;
  createSearchId: (data: SearchInputDto) => Promise<string> | null;
  createFlightSearchId: (
    data: SearchTransportInputDto
  ) => Promise<string> | null;
  getHDPProposals: (searchId: string, resultFilter?: any) => void;
  getFlightsProposals: (searchId: string, resultFilter?: any) => void;
  getFlightsRetries: number;
  noResultMessage: boolean;
  isFecthRetrieveProporsals: boolean;
  openHeaderSearch: boolean;
  setOpenHeaderSearch: (value: boolean) => void;
  priceId: string;
  currentPageNumber: number;
  cartExpired: boolean;
  setCardExpired: () => void;
  onChangePageNumber: (page: number) => void;
  getCart: (cartId: string, requestUuid: string) => Promise<void>;
  getCartConfig: (cartId: string, requestUuid: string) => void;
  getAvailableAncilliaries: (cartId: string, requestUuid: string) => void;
  getOrder: (cartId: string, requestUuid: string) => void;
  onChangeSortSelector: (selectorData: SortSelectorProps) => void;
  createCart: (requestBody: NewCartInputDto) => Promise<CreateCartResponseDto>;
  cleanCheckout: () => void;
  cart: CreateCartResponseDto | null;
  isFetchCart: boolean;
}

export const createConfigStore = ({
  brandConfig,
}: {
  brandConfig?: BrandConfig;
}) => {
  return createStore<StoreStateProps>()((set, get) => ({
    externalAuth: undefined,
    sortSelector: {
      value: SORT_SELECTOR_DEFAULT,
      ascending: true,
    },
    flightFilters: {},
    hdpFilters: {},
    brandConfig,
    priceId: null,
    isFecthRetrieveProporsals: true,
    currentSearchId: null,
    retrieveProporsals: null,
    noResultMessage: false,
    cartDetail: null,
    cartConfig: null,
    order: null,
    openHeaderSearch: false,
    availableAncilliaries: null,
    loading: false,
    cartExpired: false,
    currentPageNumber: 1,
    cart: null,
    isFetchCart: false,
    isFetchFlightsAlternativeProporsals: false,
    getFlightsRetries: 0,
    setOpenHeaderSearch: (value: boolean) => set({ openHeaderSearch: value }),
    getExternalAuth: async (
      requestBody: AuthenticatorInputDto
    ): Promise<AuthenticatedDto> => {
      try {
        const response =
          await TokenAuthService.postApiTokenAuthAuthenticateExternal({
            requestBody,
          });

        var tokenExpireDate = new Date(
          new Date().getTime() + 1000 * response.authApiModel.expireInSeconds
        );

        setCookieValue(
          tokenCookieName,
          response.authApiModel.accessToken,
          tokenExpireDate
        );
        setCookieValue(
          encriptedAuthTokenName,
          response.authApiModel.encryptedAccessToken,
          tokenExpireDate
        );
        set({
          externalAuth: response,
        });
        return response;
      } catch (e) {
        set({
          externalAuth: undefined,
        });
        console.log('🚀 ~ requestAuthInfo ~ e:', e);
        return null;
      }
    },

    cleanCheckout: () => {
      set({
        cart: null,
        cartConfig: null,
        cartDetail: null,
        order: null,
        availableAncilliaries: null,
        cartExpired: false,
      });
    },
    createCart: async (
      requestBody: NewCartInputDto
    ): Promise<CreateCartResponseDto> => {
      try {
        set({
          isFetchCart: true,
        });
        const response =
          await CartApiServiceService.postApiServicesAppCartApiServiceCreateCart(
            {
              requestBody,
            }
          );
        set({
          isFetchCart: false,
        });
        return response;
      } catch (e) {
        set({
          isFetchCart: false,
        });
        console.log('🚀 ~ createConfigStore ~ e:', e);
        return null;
      }
    },

    onChangePageNumber: (page) => {
      set({
        currentPageNumber: page,
      });
    },
    getCart: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveCart(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          cartDetail: response,
        });
      } catch (e) {
        if (e.response.data.error.message === 'CART_EXPIRED') {
          get().setCardExpired();
        }
      }
    },
    getCartConfig: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetCartConfig(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          cartConfig: response,
        });
      } catch (e) {
        if (e.response.data.error.message === 'CART_EXPIRED') {
          get().setCardExpired();
        }
      }
    },
    getAvailableAncilliaries: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetAvailableProducts(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          availableAncilliaries: response.products,
        });
      } catch (e) {
        if (e.response.data.error.message === 'CART_EXPIRED') {
          get().setCardExpired();
        }
      }
    },
    getOrder: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetOrders({
            cartId,
            requestUuid,
          });

        set({
          order: response,
        });
      } catch (e) {
        if (e.response.data.error.message === 'CART_EXPIRED') {
          get().setCardExpired();
        }
      }
    },
    createSearchId: async (data: SearchInputDto) => {
      try {
        const response =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreateSearch(
            { requestBody: data }
          );

        set({
          currentSearchId: response?.id?.value,
          loading: false,
        });

        return response?.id?.value;
      } catch (e) {
        console.log('🚀 ~ createSearchId: ~ e:', e);
        return null;
      }
    },
    createFlightSearchId: async (data: SearchTransportInputDto) => {
      try {
        const response =
          await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceCreateSearch(
            { requestBody: data }
          );

        set({
          currentSearchId: response.searchId,
          loading: false,
        });

        return response.searchId;
      } catch (e) {
        console.log('🚀 ~ createFlightSearchId: ~ e:', e);
        return null;
      }
    },
    onChangeSortSelector: (selectorData) =>
      set(() => ({ sortSelector: selectorData })),
    getHDPProposals: async (searchId, resultFilters = []) => {
      try {
        const filters = {
          ...get().hdpFilters,
          ...resultFilters.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
        };

        set(() => ({
          hdpFilters: filters,
          isFecthRetrieveProporsals: true,
        }));

        const facetsUpdated = getHDPFacets(filters);

        const currentPageNumber = get().currentPageNumber;

        const retrieveProporsals =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceRetrieveProposals(
            {
              searchId,
              requestBody: {
                page: {
                  number: currentPageNumber,
                  size: 20,
                },
                facets: facetsUpdated,
                sort: {
                  name: get().sortSelector.value,
                  ascending: get().sortSelector.ascending,
                },
                staticData: {
                  language: brandConfig.language,
                },
              },
            }
          );

        set(() => ({
          retrieveProporsals,
          isFecthRetrieveProporsals: false,
        }));
      } catch (e) {
        set(() => ({
          isFecthRetrieveProporsals: false,
          loading: false,
        }));
        console.log('🚀 ~ getProposalsWithFilters: ~ e:', e);
      }
    },
    setCardExpired: () => set({ cartExpired: true }),
    getFlightsProposals: async (searchId, newFilters = []) => {
      try {
        const freeFlightConditions =
          get().brandConfig?.brand?.freeFlightConditions;
        const isFreeFlight = !!freeFlightConditions;

        const filters = {
          ...get().flightFilters,
          ...newFilters.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
        };

        set(() => ({
          flightFilters: filters,
          isFecthRetrieveProporsals: true,
        }));

        const facetsUpdated = getFlightFacets(filters, freeFlightConditions);

        const currentPageNumber = get().currentPageNumber;

        const alternativeTransportsRequest: AdvancedSearchInput = {
          facets: facetsUpdated,
          sort: {
            name: isFreeFlight
              ? SORT_SELECTOR_FLIGHT_CAMPAING
              : get().sortSelector.value,
            ascending: get().sortSelector.ascending,
          },
          page: {
            number: currentPageNumber,
            size: 20,
          },
          filtersMetadata: true,
          staticData: {
            language: brandConfig.language,
          },
        };

        const retrieveProporsals =
          await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceRetrieveProposals(
            {
              searchId,
              requestBody: alternativeTransportsRequest,
            }
          );

        set(() => ({
          retrieveProporsals,
          isFecthRetrieveProporsals: false,
        }));
      } catch (e) {
        if (
          e.response.data.error.message === 'GENERIC_ERROR' &&
          get().getFlightsRetries < 10
        ) {
          get().getFlightsProposals(searchId, newFilters);
          set({ getFlightsRetries: get().getFlightsRetries + 1 });
        } else if (e.response.data.error.message === 'NO_CONTENT') {
          set(() => ({
            isFecthRetrieveProporsals: false,
            noResultMessage: true,
          }));
        } else {
          set(() => ({
            isFecthRetrieveProporsals: false,
            noResultMessage: true,
          }));
        }

        console.log('Error - getFlightsProposals');
      }
    },
  }));
};
