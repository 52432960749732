import {
  CityIcon,
  HotelIcon,
  PinIcon,
  PlaneIcon,
} from '@/components/LmnIcons/LmnIcons';

export const getLocationType = (locationType: string) => {
  switch (locationType) {
    case 'City':
      return <CityIcon size={24} />;
    case 'Hotel':
      return <HotelIcon size={24} />;
    case 'Tag':
      return <PinIcon size={24} />;
    case 'Airport':
      return <PlaneIcon size={24} />;
    default:
      return <CityIcon size={24} />;
  }
};
