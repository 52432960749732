import { useTranslate } from '@tolgee/react';
import * as Styled from './Footer.styled';
import ffFooterContent from './ffFooterContent.json';
import { useStore } from '@/store/useStore';

export function FreeFlightFooter() {
  const brandConfig = useStore((state) => state.brandConfig);
  const { t } = useTranslate();
  const { key, placeholders, links } = ffFooterContent.footer;
  return (
    <Styled.Container>
      <Styled.Text $primaryColor={brandConfig.theme.primaryColor}>
        {t(key, placeholders)}
      </Styled.Text>
      {links && (
        <Styled.LinksWrapper>
          {links.map((e, index) => (
            <a
              key={`${index}_footer_links`}
              href={e.placeholders.url}
              target="_blank"
            >
              {t(e.key)}
            </a>
          ))}
        </Styled.LinksWrapper>
      )}
    </Styled.Container>
  );
}
