export const refineAge = (
  age: number,
  version: 1 | 2,
  t: (key: string) => string
) => {
  const isV1 = version === 1;
  switch (age) {
    case 0:
      return isV1
        ? `${t('general.traveller_type.infant')}, 0-11 ${t('general.time.months')}`
        : `0-11 ${t('general.time.months')}`;
    case 1:
      return isV1
        ? `${t('general.traveller_type.infant')}, 12-23 ${t('general.time.months')}`
        : `12-23 ${t('general.time.months')}`;
    default:
      return `${age} ${t('search.years')}`;
  }
};
