import { useStore } from '@/store/useStore';
import { getCookieValue } from '@/store/utils/authenticateExternal/getCookieValue';
import { useState } from 'react';
import CookiesBanner from './components/CookiesBanner/CookiesBanner';
import { LmnConsent } from './components/CookiesBanner/LmnConsent/LmnConsent';

export const CookiesConsent = () => {
  const [brandConfig] = useStore((s) => [s.brandConfig]);
  const showCookiesConsentState = useState<boolean>(
    !getCookieValue('cookies-acceptance')
  );
  const [showCookiesConsent] = showCookiesConsentState;

  return (
    <>
      {brandConfig.brand.brandOrigin === 'Lastminute' ? (
        <LmnConsent
          language={brandConfig.language}
          country={brandConfig.country}
          brand={brandConfig.brand.brandBase}
        />
      ) : (
        showCookiesConsent && (
          <CookiesBanner visibilityState={showCookiesConsentState} />
        )
      )}
    </>
  );
};
