import { DocumentTitle } from '@/components/DocumentTitle';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { getSearchParam } from '@/utils/getSearchParam';
import * as React from 'react';
import { Offers } from './Offers/Deals/Deals';
import { FavouriteDestinations } from './Offers/FavouriteDestinations/FavouriteDestinations';
import { TravelSearchInfo } from './TravelSearchInfo';

export function TravelSearch() {
  const brandConfig = useStore((state) => state.brandConfig);

  const track = useTrack();

  React.useEffect(() => {
    if (brandConfig.landingContent && getSearchParam('product')) {
      track({
        eventName: 'opened.search-landing',
        logLevel: 'info',
      });
    }
  }, [brandConfig.landingContent]);

  if (!brandConfig.landingContent) {
    return null;
  }

  const { hero, productExplanation } = brandConfig.landingContent;
  const offers = brandConfig.offers;

  return (
    <>
      <DocumentTitle title={brandConfig.brand.pageTitle} />
      <Header />
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        backgroundUrl={hero.backgroundUrl}
      />
      {productExplanation && (
        <TravelSearchInfo
          title={productExplanation.title}
          subtitle={productExplanation.subtitle}
          description={productExplanation.description}
        />
      )}
      {offers && (
        <React.Fragment>
          <Offers />
          <FavouriteDestinations />
        </React.Fragment>
      )}
      <Footer />
    </>
  );
}
