import styled from '@emotion/styled';

export const InnerContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const Title = styled.h1`
  color: #3c3c3c;
  font-size: 32px;
`;

export const Subtitle = styled.h2`
  color: #3c3c3c;
  font-size: 24px;
  font-weight: 500;
`;

export const Text = styled.div`
  margin-bottom: 0.5em;
  color: #3c3c3c;
  font-size: 16px;
  line-height: 1.5;

  & ul {
    list-style: none;
    padding-left: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 2em 0;
`;
