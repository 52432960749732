import { PhoneIcon } from '@/components/LmnIcons/LmnIcons';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { Button, Popover, theme } from 'antd';
import * as Styled from './Callbutton.styled';

export function CallUsButton() {
  const { token } = theme.useToken(); // TODO Remove antd
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const { t } = useTranslate();
  const track = useTrack();
  const brandConfig = useStore((state) => state.brandConfig);

  const { phonePrivacyPolicyUrl, phoneNumber } = brandConfig.brand;

  const handleClick = () => {
    track({
      eventName: 'clicked.header.phone',
      logLevel: 'info',
    });
  };

  return (
    <Popover
      content={
        <Styled.PopoverContent>
          {isFreeFlight && (
            <strong>{t('header.contact.assistance_lmn')}</strong>
          )}
          <span>{t('header.contact.provider_cost')}</span>
          {phonePrivacyPolicyUrl && (
            <Styled.Link href={phonePrivacyPolicyUrl} target="_blank">
              {t('header.contact.privacy_policy')}
            </Styled.Link>
          )}
          <Button type="primary" block href={`tel:${phoneNumber}`}>
            {t('header.contact.call_us')}
          </Button>
        </Styled.PopoverContent>
      }
      trigger="click"
    >
      <Styled.CallButton
        onClick={handleClick}
        bgcolor={token.colorFillQuaternary}
      >
        <PhoneIcon size={24} />
        <span> {phoneNumber}</span>
      </Styled.CallButton>
    </Popover>
  );
}
