import { configMapper } from '@/store/utils/configMapper';
import { getInitialConfig } from '@/store/utils/getInitialState';
import { captureEvent } from '@sentry/react';
import * as React from 'react';

export function useGetInitialConfig() {
  const [initialConfig, setInitialConfig] = React.useState(null);

  React.useEffect(() => {
    getInitialConfig()
      .then(async (configuration) => {
        const configId = configuration.data.id;
        const domain = configuration.data.attributes.domain;

        const brandConfig = configMapper({
          id: configId,
          domain: domain,
          brandConfig: configuration.data.attributes,
        });

        setInitialConfig({ brandConfig });
      })
      .catch((error) => {
        captureEvent({ message: 'error.get-initial-state' }, { data: error });
        return null;
      });
  }, []);

  return initialConfig;
}
