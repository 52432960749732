import { getHoursString } from '@/utils/timeUtils';

export const getAlternativeFlightsFacets = (filters: any) => {
  const outboundStops =
    filters.outboundStops && filters.outboundStops.length > 0
      ? [
          {
            name: 'outboundStops',
            values: filters.outboundStops,
          },
        ]
      : [];
  const inboundStops =
    filters.inboundStops && filters.inboundStops.length > 0
      ? [
          {
            name: 'inboundStops',
            values: filters.inboundStops,
          },
        ]
      : [];

  const arrivalSame =
    filters.arrivalSame && filters.arrivalSame.length > 0
      ? [
          {
            name: 'dep-arr',
            values: filters.arrivalSame,
          },
        ]
      : [];

  const airlines =
    filters.airlines && filters.airlines.length > 0
      ? [
          {
            name: 'inclusiveProviders',
            values: filters.airlines,
          },
        ]
      : [];

  const outboundDepartureLocation =
    filters.outboundDepartureLocation &&
    filters.outboundDepartureLocation.length > 0
      ? [
          {
            name: 'outbound-dep-airport',
            values: filters.outboundDepartureLocation,
          },
        ]
      : [];

  const outboundArrivalLocation =
    filters.outboundArrivalLocation &&
    filters.outboundArrivalLocation.length > 0
      ? [
          {
            name: 'outbound-arr-airport',
            values: filters.outboundArrivalLocation,
          },
        ]
      : [];

  const inboundArrivalLocation =
    filters.inboundArrivalLocation && filters.inboundArrivalLocation.length > 0
      ? [
          {
            name: 'inbound-arr-airport',
            values: filters.depArrSameLocation
              ? filters.outboundDepartureLocation
              : filters.inboundArrivalLocation,
          },
        ]
      : [];

  const inboundDepartureLocation =
    filters.inboundDepartureLocation &&
    filters.inboundDepartureLocation.length > 0
      ? [
          {
            name: 'inbound-dep-airport',
            values: filters.depArrSameLocation
              ? filters.outboundArrivalLocation
              : filters.inboundDepartureLocation,
          },
        ]
      : [];

  const facets = [
    ...outboundStops,
    ...inboundStops,
    ...airlines,
    ...outboundDepartureLocation,
    ...outboundArrivalLocation,
    ...inboundArrivalLocation,
    ...inboundDepartureLocation,
    ...arrivalSame,
  ];

  return facets;
};

export const getFlightFacets = (
  filters: any,
  freeFlightConditions: {
    adults: number;
    departureCountry: string;
    voucherAmount: number;
    tenantDomain: string;
  }
) => {
  const isFreeFlight = !!freeFlightConditions;
  const freeFlightVoucherAmount = freeFlightConditions?.voucherAmount;

  const priceRange =
    filters.budgetRange?.length > 0
      ? [
          {
            name: 'price',
            range: {
              from: `${filters.budgetRange[0]}`,
              to: `${filters.budgetRange[1]}`,
            },
          },
        ]
      : [];

  const outboundStops =
    filters.outboundStops && filters.outboundStops.length > 0
      ? [
          {
            name: 'outboundStops',
            values: filters.outboundStops,
          },
        ]
      : [];
  const inboundStops =
    filters.inboundStops && filters.inboundStops.length > 0
      ? [
          {
            name: 'inboundStops',
            values: filters.inboundStops,
          },
        ]
      : [];

  const luggage =
    filters.luggageOptions && filters.luggageOptions.length > 0
      ? [
          {
            name: 'baggageIncluded',
            values:
              filters.luggageOptions.length === 2
                ? ['CABIN_CHECKED']
                : ['CABIN_CHECKED', ...filters.luggageOptions],
          },
        ]
      : [];

  const outboundDepartureTime =
    filters.outboundDepartureTime && filters.outboundDepartureTime.length > 0
      ? [
          {
            name: 'outboundDepartureTime',
            range: {
              from: getHoursString(filters.outboundDepartureTime[0]),
              to: getHoursString(filters.outboundDepartureTime[1]),
            },
          },
        ]
      : [];

  const inboundDepartureTime =
    filters.inboundDepartureTime && filters.inboundDepartureTime.length > 0
      ? [
          {
            name: 'inboundDepartureTime',
            range: {
              from: getHoursString(filters.inboundDepartureTime[0]),
              to: getHoursString(filters.inboundDepartureTime[1]),
            },
          },
        ]
      : [];

  const connectionTime = filters.connectionTime
    ? [
        {
          name: 'connectionTime',
          range: {
            from: '0',
            to: `${filters.connectionTime}`,
          },
        },
      ]
    : [];

  const tripDuration = filters.tripDuration
    ? [
        {
          name: 'tripDuration',
          range: {
            from: '0',
            to: `${filters.tripDuration}`,
          },
        },
      ]
    : [];

  const airlines =
    filters.airlines && filters.airlines.length > 0
      ? [
          {
            name: 'inclusiveProviders',
            values: filters.airlines,
          },
        ]
      : [];

  const outboundDepartureLocation =
    filters.outboundDepartureLocation &&
    filters.outboundDepartureLocation.length > 0
      ? [
          {
            name: 'outboundDepartureLocation',
            values: filters.outboundDepartureLocation,
          },
        ]
      : [];

  const outboundArrivalLocation =
    filters.outboundArrivalLocation &&
    filters.outboundArrivalLocation.length > 0
      ? [
          {
            name: 'outboundArrivalLocation',
            values: filters.outboundArrivalLocation,
          },
        ]
      : [];

  const inboundArrivalLocation =
    filters.inboundArrivalLocation && filters.inboundArrivalLocation.length > 0
      ? [
          {
            name: 'inboundArrivalLocation',
            values: filters.depArrSameLocation
              ? filters.outboundDepartureLocation
              : filters.inboundArrivalLocation,
          },
        ]
      : [];

  const inboundDepartureLocation =
    filters.inboundDepartureLocation &&
    filters.inboundDepartureLocation.length > 0
      ? [
          {
            name: 'inboundDepartureLocation',
            values: filters.depArrSameLocation
              ? filters.outboundArrivalLocation
              : filters.inboundDepartureLocation,
          },
        ]
      : [];

  const onlyFlight = [
    {
      name: 'transportType',
      values: ['ONLY_FLIGHT'],
    },
  ];

  const freeFlightPriceRange = [
    {
      name: 'price',
      range: {
        from: `${0}`,
        to: `${freeFlightVoucherAmount * 100}`,
      },
    },
  ];

  const finalPriceRange = isFreeFlight ? freeFlightPriceRange : priceRange;

  const facets = [
    ...onlyFlight,
    ...finalPriceRange,
    ...luggage,
    ...outboundStops,
    ...inboundStops,
    ...outboundDepartureTime,
    ...inboundDepartureTime,
    ...connectionTime,
    ...tripDuration,
    ...airlines,
    ...outboundDepartureLocation,
    ...outboundArrivalLocation,
    ...inboundArrivalLocation,
    ...inboundDepartureLocation,
  ];

  return facets;
};
