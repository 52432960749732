import useExternalScripts from '@/hooks/useExternalScripts';
import { useEffect } from 'react';

export const LmnConsent = (props: {
  language: string;
  country: string;
  brand: string;
}) => {
  const { addScript } = useExternalScripts();

  useEffect(() => {
    addScript({
      url: 'https://assets.staticroot.com/cookie-consent-manager-ui/3/cookie-consent-manager.js',
      attributes: {
        id: 'cookie-consent-manager',
        'data-brand': props.brand,
        'data-country': props.country,
        'data-lang': props.language,
        'data-theme': props.brand,
        'data-host': 'https://assets.staticroot.com/cookie-consent-manager-ui/',
        'data-version': '3',
      },
    });
  }, []);

  return null;
};
