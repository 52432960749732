import { ImagesCarousel as MuiImagesCarousel } from '@/components/ImagesCarousel';
import styled from '@emotion/styled';

export const FlashSaleOffersContainer = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  padding-top: 4em;
  overflow: hidden;
`;

export const FlashSaleOffersBackgroundImage = styled.div<{
  $fromLM: boolean;
  $backgroundColor: string;
}>`
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: ${({ $fromLM }) =>
    $fromLM
      ? "url('https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Artwork/Deals/Last%20Minute%20deals_2024/MHP_background_desktop_final')"
      : ''};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  overflow: hidden;

  &:before {
    content: '';
    display: ${({ $fromLM }) => ($fromLM ? 'none' : 'block')};
    position: absolute;
    width: calc(100% + 200px);
    height: 290px;
    top: 10px;
    left: -100px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    rotate: 1deg;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  color: white;
  font-weight: 700;
`;
export const Subtitle = styled.span`
  font-size: 14px;
  color: white;
  font-weight: 400;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
export const OffersCarousel = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 0px;
    scroll-snap-type: x mandatory;

    & > div {
      flex: 0 0 auto;
      @media (max-width: 576px) {
        width: calc(100% - 100px);
      }
      width: 100%;
      scroll-snap-align: start;
    }

    -ms-overflow-style: none; /* IE y Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const OfferContainer = styled.div`
  @media (max-width: 768px) {
    padding-left: 16px;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AtolImage = styled.div`
  background-image: url('https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Artwork/Other/Certificates/ATOL/Atol_text_white');
  background-size: cover; /* Escala la imagen para cubrir el ancho completo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  width: 197px;
  height: 51px;
  min-width: 200px;
`;

export const ImagesCarouselContainer = styled.div`
  position: relative;
`;
export const OfferContent = styled.a`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: #3c3c3c;

  padding: 8px;
`;

export const OfferName = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;
export const PriceContainer = styled.span<{
  $fromLM: boolean;
  $textColor: string;
}>`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${({ $fromLM, $textColor }) => ($fromLM ? '#f2007d' : $textColor)};
`;
export const Price = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-right: 4px;
  margin-left: 4px;
`;
export const HotelName = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #8c8c92;
  display: flex;
  gap: 0px 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Details = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;
export const Location = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  color: white;
  bottom: 16px;
  left: 16px;
  z-index: 2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
export const CounterContainer = styled.div`
  position: absolute;
  top: 52px;
  right: 16px;
  z-index: 2;
`;

export const ImagesCarousel = styled(MuiImagesCarousel)`
  width: 100% !important;
  height: 320px;

  & > div:last-child {
    text-align: right;
    bottom: 14px !important;
    right: 8px;
  }
`;

export const OfferType = styled.div`
  background-color: #000000cc;
  border-radius: 6px;
  height: 34px;
  align-items: center;
  min-width: unset;
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  padding: 5px 8px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
`;
