import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  color: #74747c;
  font-size: 14px;
  line-height: 20px;
  flex-wrap: wrap;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #eaeaeb;
  height: 20px;
  margin: 0px 8px;
`;
