import * as React from 'react';

const mobileValue = 768;
const tabletValue = 1024;

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= mobileValue
  );
  const [isTablet, setIsTablet] = React.useState(
    window.innerWidth <= tabletValue
  );

  const handleListener = React.useCallback(() => {
    setIsMobile(window.innerWidth <= mobileValue);
    setIsTablet(window.innerWidth <= tabletValue);
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleListener);

    return () => {
      window.removeEventListener('resize', handleListener);
    };
  }, []);

  return { isMobile, isTablet };
}
