import styled from '@emotion/styled';

export const FlashSaleOffersContainer = styled.div`
  margin: 3em 0;
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  color: #3c3c3c;
  font-weight: 700;
`;
export const Subtitle = styled.span`
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 400;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
export const OffersCarousel = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-left: 16px;
  margin-right: 16px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 0px;
    margin-left: 0px;
    margin-right: 0px;
    scroll-snap-type: x mandatory;

    & > a {
      flex: 0 0 auto;
      width: 100%;
      @media (max-width: 576px) {
        width: calc(100% - 100px);
      }
      scroll-snap-align: start;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const DetinationContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  z-index: 1;
  pointer-events: none;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;
export const Tag = styled.span<{ color: string }>`
  color: white;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 2px 8px;
  margin: auto 0px 0px auto;
`;

export const DestinationLink = styled.a`
  @media (max-width: 768px) {
    padding-left: 16px;
  }
  text-decoration: none;
`;
export const DestinationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  position: relative;
  text-decoration: none;
`;
export const DestinationImage = styled.div<{ url: string }>`
  opacity: 0.7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${({ url }) => url}');
  transition:
    transform 0.3s ease-out,
    background-size 0.3s ease-out;
  transform: scale(1.01);
  &:hover {
    transform: scale(1.03);
  }
  height: 100%;
  width: 100%;
  position: absolute;
  object-position: 50% 50%;
`;
