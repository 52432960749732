import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Switch as MuiSwitch } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const ConsentWrapper = styled.div`
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  font-family: open sans;
  font-size: 12px;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.3);
    z-index: 1;
    pointer-events: auto;
  }
`;

export const ConsentBanner = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;

export const ConsentContainer = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
  transition: transform 0.4s ease;
  -webkit-font-smoothing: antialiased;
  padding: 24px;
  border-radius: 0 0;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
`;

export const Title = styled.span`
  display: block;
  font-weight: 700;

  font-size: 24px;

  color: #3c3c3c;
  line-height: 36px;
`;

export const Text = styled.div`
  color: #8c8c92;
  a {
    cursor: pointer;
    color: #8c8c92;
    text-decoration: underline;
  }
  button {
    color: #8c8c92;
    text-decoration: underline;
    font-size: inherit;
    font-family: inherit;
    border: none;
    background: 0 0;
    padding: 0;
    line-height: 16px;
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: #8c8c92;
  overflow-y: auto;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  padding-bottom: 16px;
`;

export const Button = styled.div`
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #000000;
  cursor: pointer;
  width: fit-content;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

export const ConsentButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
export const CustomButtons = styled.div`
  margin-right: 16px;
`;

export const ButtonPrimary = styled(Button)<{
  $mainColor: string;
  $textColor: string;
  $size: 'fit' | 'large';
}>`
  background-color: ${({ $mainColor }) => $mainColor || undefined};
  color: ${({ $textColor }) => $textColor || undefined};
  padding: ${({ $size }) =>
    $size === 'fit' ? '10px' : $size === 'large' ? '10px 100px' : '10px'};
  border: 1px solid ${({ $textColor }) => $textColor || '#000000'};
  border-radius: 6px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSecondary = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #404040;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  text-decoration: none;
  background-size: 21px;
  background-position: center left 10px;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid transparent;
`;

export const DenyButton = styled(ButtonSecondary)`
  position: absolute;
  top: 0;
  right: 32px;
`;

export const ManageCookiesBanner = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #00000042;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    align-content: flex-end;
  }
`;

export const ManageCookiesContainer = styled.div`
  width: 900px;
  max-height: 80vh;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 16px;

  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const ModalHeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;
export const BackButton = styled(Button)`
  padding: 6px 6px 4px 6px;
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: unset !important;
`;
export const FullDocumentButton = styled.a`
  font-size: 14px !important;
  font-weight: 700 !important;
  display: inline-flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  color: #404040 !important;
  background-color: rgba(0, 0, 0, 0.07) !important;
  border-radius: 6px !important;
  text-decoration: none !important;
  background-size: 21px !important;
  background-position: center left 10px !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
  border: 1px solid transparent !important;
`;

export const ModalContent = styled.div`
  padding: 0px 24px;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }

  overflow-y: auto;
`;

export const CookiesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const AllCookiesButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;

  padding-top: 8px;
  @media (max-width: 768px) {
    padding-top: 16px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    flex-flow: column;
  }
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;

  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    flex-flow: column-reverse;
  }
`;
export const RejectIcon = styled(CloseIcon)`
  color: 'black';
  margin-right: 5px;
`;
export const AcceptIcon = styled(CheckIcon)<{ $color: string }>`
  color: ${({ $color }) => $color};
  margin-right: 5px;
`;
export const CookieTypeSelector = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CookieTypeTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-flow: column;
  }
`;
export const InfoAndSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Switch = muiStyled(MuiSwitch)(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    backgroundColor: 'white',
    padding: 3,
    marginTop: 3,
    marginLeft: 3,
    '&:hover': {
      backgroundColor: 'white !important',
    },
    '&.Mui-checked': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      marginLeft: 0,
      transform: 'translateX(35px)',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 25,
    height: 32,
    width: 64,
  },
  '& .Mui-disabled': {
    '& + .MuiSwitch-track': {
      backgroundColor: '#ccc !important',
    },
    '&.Mui-checked': {
      '& svg': {
        fill: '#ccc',
      },
    },
  },
}));

export const ShowDescription = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.75);
  & svg {
    fill: rgb(204, 204, 204);
  }
`;
