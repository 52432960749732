import { TolgeeProvider as TolgeeReactProvider } from '@tolgee/react';
import { useTolgeeConfig } from './useTolgeeConfig';
import type { TolgeeProviderProps } from './TolgeeProvider.types';

export function TolgeeProvider({ children }: TolgeeProviderProps) {
  const tolgeeConfig = useTolgeeConfig();

  return (
    <TolgeeReactProvider tolgee={tolgeeConfig}>{children}</TolgeeReactProvider>
  );
}
