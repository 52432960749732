import * as React from 'react';
import { useStore } from '@/store/useStore';
import { baseTheme } from './baseTheme';

export function useAntdConfig() {
  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, primaryColorBg, secondaryColor } = brandConfig.theme;

  return React.useMemo(
    () => ({
      token: {
        ...baseTheme.token,
        colorPrimary: primaryColor,
        colorPrimaryBg: primaryColorBg,
        colorFillQuaternary: secondaryColor,
      },
      components: {
        ...baseTheme.components,
        Slider: {
          colorPrimaryBorder: primaryColor,
          colorPrimaryBorderHover: primaryColor,
        },
        Pagination: {
          itemActiveBg: primaryColorBg,
        },
      },
    }),
    []
  );
}
