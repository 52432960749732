interface ScriptAttributes {
  [key: string]: string;
}

interface UseExternalScriptsReturn {
  addScript: ({
    url,
    attributes,
  }: {
    url: string;
    attributes: ScriptAttributes;
  }) => void;
  removeScript: () => void;
}

export default function useExternalScripts(): UseExternalScriptsReturn {
  const head = document.querySelector('head') as HTMLHeadElement;
  const script = document.createElement('script');

  const addScript = ({
    url,
    attributes,
  }: {
    url: string;
    attributes: ScriptAttributes;
  }): void => {
    const attributesKeys = Object.keys(attributes);
    script.setAttribute('src', url);
    attributesKeys.forEach((att) => {
      script.setAttribute(att, attributes[att]);
    });

    head.appendChild(script);
  };

  const removeScript = (): void => {
    if (head.contains(script)) {
      head.removeChild(script);
    }
  };

  return { addScript, removeScript };
}
