import { useStore } from '@/store/useStore';
import CurrencyFormatter from '@/utils/currencyFormatter';

export const useCurrencyFormatter = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { language, country, brand } = brandConfig;

  return new CurrencyFormatter(
    `${language}-${country}`,
    brand.currency || 'EUR',
    {
      minimumFractionDigits: 2,
    }
  );
};
