import { useFlag } from '@unleash/proxy-client-react';
import { getSearchParam } from '../utils/getSearchParam';

export function useGetFlag(key: string) {
  const flagValue = useFlag(key);
  const queryParam = getSearchParam(key);

  if (queryParam) {
    return queryParam === 'true' ? true : false;
  } else {
    return flagValue;
  }
}
